import * as React from "react";

import Layout from "../../components/Layout";
import WorkRoll from "../../components/WorkRoll";
import WorkOpener from "../../components/WorkOpener";

// import $ from "jquery";

export default class WorkIndexPage extends React.Component {

  constructor(props) {
    super(props);
    // this.state = {
    //   active: false,
    //   navBarActiveClass: "",
    // };
    // this.toggleHamburger = this.toggleHamburger.bind(this);

    
  }

  // componentDidMount() {

  //   console.log("height: " +  document.documentElement.scrollHeight + " window: " + window.outerHeight)
  //   // $("#logo").css("margin-top", "10vh");
  // }

  render() {
    return (
      <Layout>
        <div className="placeholder"></div>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('../img/DSC01135.jpg')`,
            backgroundPosition: "50% 50%",
            height: "78vh",
            top: "-15vh",
          }}
        >
        </div>
        <section className="section" style={{ position: "relative", top: "-15vh", }}>
          <div className="container">
            <div className="content" >
              <WorkOpener />
              <WorkRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
