import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import "../components/all.sass"
// import PreviewCompatibleImage from './PreviewCompatibleImage'
import { FadeInSection } from './FadeInSection'

import $ from "jquery";

const WorkRollTemplate = (props) => {
  
  const { edges: posts } = props.data.allMarkdownRemark;

  return (
    <div className="columns is-1" style={{ marginTop: "5rem"}}>
      <div id="column-1" className="column is-4" style={{ padding: "0"}}>
        {posts &&
          posts.map(({ node: post }, index) => {
            return ( index % 3 == 0 ?
              <Link
                className=""
                to={post.fields.slug}
              >
                {/* { console.log(post.frontmatter)} */}
                <figure 
                  className="image"
                  onMouseOver={ () => $("#" + post.id).attr("src", post.frontmatter.hoveredimage.childImageSharp.gatsbyImageData.images.fallback.src) }
                  onMouseOut={ () => $("#" + post.id).attr("src", post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src) }
                >
                  <img
                    id={ post.id }
                    src={ post.frontmatter.featuredimage ? post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src : "" }
                    alt={ post.frontmatter.featuredimage ? post.frontmatter.featuredimage.alt : "" }
                  ></img>
                  <FadeInSection>
                    <button 
                      id={"work-button-" + index}
                      className="work-button"
                      style={{ 
                        fontSize: "1.5rem",
                        fontWeight: "100",
                        position: "relative", 
                        top: "-6rem", 
                        padding: "1rem",
                        paddingTop: "0.7rem",
                        border: "1px solid black",
                        borderRadius: "50px",
                        backgroundColor: "rgb(255,255,255, 0.5)",
                        cursor: "pointer"
                      }}
                    >
                      see more &rarr;
                    </button>
                  </FadeInSection>
                </figure>         
              </Link>
            : ""
            )
          })
        }
      </div>
      <div 
        id="column-2" 
        className="column is-4"
        style={{ padding: "0"}}>
        {posts &&
          posts.map(({ node: post }, index) => {
            return ( index % 3 == 1 ?
              <Link
                className=""
                to={post.fields.slug}             
              >
                <figure 
                  className="image"
                  onMouseOver={ () => $("#" + post.id).attr("src", post.frontmatter.hoveredimage.childImageSharp.gatsbyImageData.images.fallback.src) }
                  onMouseOut={ () => $("#" + post.id).attr("src", post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src) }
                >
                  <img
                    id={ post.id }
                    src={ post.frontmatter.featuredimage ? post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src : ""}
                    alt={ post.frontmatter.featuredimage ? post.frontmatter.featuredimage.alt : "" }
                  ></img>
                  <FadeInSection>
                    <button 
                      id={"work-button-" + index}
                      className="work-button"
                      style={{ 
                        fontSize: "1.5rem",
                        fontWeight: "100",
                        position: "relative", 
                        top: "-6rem", 
                        padding: "1rem",
                        paddingTop: "0.7rem",
                        border: "1px solid black",
                        borderRadius: "50px",
                        backgroundColor: "rgb(255,255,255, 0.5)",
                        cursor: "pointer"
                      }}
                    >
                      see more &rarr;
                    </button>
                  </FadeInSection>
                </figure>
              </Link>
            : ""
            )
          })
        }
      </div>
      <div id="column-3" className="column is-4" style={{ padding: "0"}}>
        {posts &&
          posts.map(({ node: post }, index) => {
            return ( index % 3 == 2 ?
              <Link
                className=""
                to={post.fields.slug}
                // style={{
                //   height: "30vh !important"
                // }}
              >
                <figure 
                  className="image"
                  onMouseOver={ () => $("#" + post.id).attr("src", post.frontmatter.hoveredimage.childImageSharp.gatsbyImageData.images.fallback.src) }
                  onMouseOut={ () => $("#" + post.id).attr("src", post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src) }
                >
                  <img
                    id={ post.id }
                    src={ post.frontmatter.featuredimage ? post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.images.fallback.src : "" }
                    alt={ post.frontmatter.featuredimage ? post.frontmatter.featuredimage.alt : "" }
                  ></img>
                  <FadeInSection>
                    <button 
                      id={"work-button-" + index}
                      className="work-button"
                      style={{ 
                        fontSize: "1.5rem",
                        fontWeight: "100",
                        position: "relative", 
                        top: "-6rem", 
                        padding: "1rem",
                        paddingTop: "0.7rem",
                        border: "1px solid black",
                        borderRadius: "50px",
                        backgroundColor: "rgb(255,255,255, 0.5)",
                        cursor: "pointer"
                      }}
                    >
                      see more &rarr;
                    </button>
                  </FadeInSection>
                </figure>
              </Link>
            : ""
            )
          })
        }
      </div>
    </div>
  )
}

WorkRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function WorkRoll() {
  return (
    <StaticQuery
      query={graphql`
        query WorkRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___alt] }
            filter: { frontmatter: { templateKey: { eq: "work-page" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1200
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                  hoveredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1200
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                  alt
                  intro {
                    images {
                      image {
                        childImageSharp {
                          gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
                        }
                      }
                      alttext
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <WorkRollTemplate data={data} count={count} />}
    />
  );
}
