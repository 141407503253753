import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

// import $ from "jquery";

const WorkOpenerTemplate = (props) => {
  
  const { edges: posts } = props.data.allMarkdownRemark;

  return (
    <div className="columns is-1">
      <div className="column is-12">
        {posts &&
          posts.map(({ node: post }) => {
            return ( 
              <div>
                <h1>{ post.frontmatter.title }</h1>
                <div className="" dangerouslySetInnerHTML={{ __html: post.html }}></div>
              </div>
              
            )
          })
        }
      </div>
    </div>
  )
}

WorkOpener.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function WorkOpener() {
  return (
    <StaticQuery
      query={graphql`
        query WorkOpenerQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "work-opener" } } }
          ) {
            edges {
              node {
                id
                html
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <WorkOpenerTemplate data={data} count={count} />}
    />
  );
}
